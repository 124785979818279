import React from "react";
import Card from "../Card";
import GigImages from "../../constants/GigImages";
import { searchCountryByCode } from '../../utils/countryUtils';

import { FaTicket } from "react-icons/fa6";
import { FaMap } from "react-icons/fa6";

const getRandImage = () => {
  return GigImages[Math.floor(Math.random() * GigImages.length)];
};

const Gig = ({ date, start_time, location, description, ticket_link, country,context }) => {
  const cardClass = context === 'swipe' ? 'card flex flex-col' : 'card flex flex-row';
  const imageClass = context === 'swipe' ? 'card-image' : 'card-image w-1/3';
  const contentClass = context === 'swipe' ? '' : 'w-2/3 pl-4';
  const [countryData, setCountryData] = React.useState({ flag: "", name: "" });

  React.useEffect(() => {
    const fetchCountryData = async () => {
      if(country){
        const countryObject = await searchCountryByCode(country);
        if (countryObject) {
          setCountryData({ flag: countryObject.flag, name: countryObject.name });
        }      
      }
    };
    fetchCountryData();
  }, [country]);


  // Date formatting will be YYYY-MM-DD
  const [year, month, day] = date.split("-");
  const formattedDate = new Date(year, month - 1, day)
    .toLocaleDateString("en-UK", {
      weekday: "long",
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    })
    .replace(",", " |");

  let formattedTime = "To be announced";
  if (start_time) {
    start_time = start_time.replace("Z", "");
    const [hours, minutes, seconds] = start_time.split(":").map(Number);
    const time = new Date();
    time.setHours(hours, minutes, seconds, 0);
    formattedTime = time.toLocaleTimeString("en-Uk", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    formattedTime = formattedTime.replace(/^0/, "");
  }

  const image = getRandImage();

  return (
    <div className={cardClass}>
      <div className="flex-center mt-4">
        <img className={imageClass} src={image} alt="Celine Experience" />
      </div>
      <div className={contentClass}>
        <div className="mt-auto mb-4">
          {description && description.length > 0 && (
            <p className="gig-description">{description}</p>
          )}
          <div>
            <hr className="gig-break" />
            <p className="gig-location">
              {countryData.flag && (
                <span className="mr-2" title={countryData.name}>
                  {countryData.flag}
                </span>
              )}
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(location)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="gig-location-text"
              >
                <div className="flex items-center justify-center">
                  {location} <FaMap className="ml-2 md:inline-block inline" />
                </div>
              </a>
            </p>
            <div className="gig-time flex justify-center items-center min-w-max whitespace-nowrap">
              <p>
                {formattedDate} @ {formattedTime}
              </p>
            </div>
            <hr className="gig-break" />
            <div className="gig-ticket-container">
              <a href={ticket_link} target="_blank" rel="noopener noreferrer">
                <button className="gig-ticket-button text-xl border-2 rounded py-2 px-4">
                  <span className="flex items-center">Get Tickets</span>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gig;
