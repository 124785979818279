// src/components/events/EventsView.jsx
import React from 'react';
import Event from './Event';

const EventsView = ({ events }) => {
  return (
    <div className="flex justify-center w-full mt-1">
      <div className="w-full max-w-7xl">
        <div className="flex flex-col items-center space-y-4">
          {events.map((event, index) => (
            <Event key={index} {...event} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EventsView;