import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import EventsView from '../components/events/EventsView';
import EventFilter from '../components/events/EventFilter'; // Import EventFilter
import Page from "../components/Page";
import { BeatLoader } from 'react-spinners';
import { GiTerror } from 'react-icons/gi';
import { FaCalendarPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { convertTime } from '../utils/convertTime';

const Events = () => {
  const { page } = useParams();
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalEvents, setTotalEvents] = useState(0);
  const [filters, setFilters] = useState({ location: '', startTime: '', country: '', fromDate: '', toDate: '' });
  const currentPage = parseInt(page, 10) || 1;
  const eventsPerPage = 10;

  useEffect(() => {
    const fetchTotalEvents = async () => {
      try {
        const response = await axios.get('/api/v2/events/count');
        setTotalEvents(response.data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchTotalEvents();
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      try {
        const skip = (currentPage - 1) * eventsPerPage;
        const formattedStartTime = filters.startTime ? convertTime(filters.startTime) : '';
        const response = await axios.get('/api/v2/events', {
          params: {
            skip,
            limit: eventsPerPage,
            location: filters.location,
            startTime: formattedStartTime,
            country: filters.country,
          },
        });
        let events = response.data;

        // Client-side date filtering
        if (filters.fromDate || filters.toDate) {
          const from = filters.fromDate ? new Date(filters.fromDate) : null;
          const to = filters.toDate ? new Date(filters.toDate) : null;
          events = events.filter(event => {
            const eventDate = new Date(event.date);
            return (
              (!from || eventDate >= from) &&
              (!to || eventDate <= to)
            );
          });
        }

        setEvents(events);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [currentPage, filters]);

  const handlePageChange = (pageNumber) => {
    navigate(`/events/${pageNumber}`);
  };

  const totalPages = Math.ceil(totalEvents / eventsPerPage);

  return (
    <Page title="Events">
      <div className="flex flex-col items-center justify-center min-h-screen space-y-6">
        <h1 className="text-4xl font-bold text-center my-8">Upcoming Events</h1>
        <EventFilter setFilters={setFilters} allEvents={events} />
        {loading ? (
          <div className="flex flex-col justify-center items-center space-y-6">
            <BeatLoader color="#e5bc3c" loading={true} size={15} />
          </div>
        ) : error ? (
          <div className="flex flex-col justify-center items-center space-y-6">
            <GiTerror size={100} />
            <p className="text-xl text-error">{error}</p>
          </div>
        ) : totalEvents === 0 ? (
          <div className="flex flex-col justify-center items-center space-y-6">
            <p className="text-xl text-celine-gold">
              No events available at {filters.date || 'any date'} in {filters.location || 'any location'} at {filters.startTime || 'any time'}.
            </p>
            <Link to="/login">
              <FaCalendarPlus className="text-grey-light" size={50} />
            </Link>
          </div>
        ) : (
          <>
            <EventsView events={events} />
            <div className="flex justify-center mt-4">
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  className={`px-4 py-2 mx-1 border rounded ${currentPage === index + 1 ? 'bg-error text-white' : 'bg-white text-black'}`}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </>
        )}
      </div>
    </Page>
  );
};

export default Events;