import React, { useState, useEffect } from 'react';

const EventFilter = ({ setFilters, allEvents }) => {
  const [location, setLocation] = useState('');
  const [startTime, setStartTime] = useState('');
  const [country, setCountry] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  // Extract unique locations and countries from allEvents
  const uniqueLocations = [...new Set(allEvents.map(event => event.location))];
  const uniqueCountries = [...new Set(allEvents.map(event => event.country))];

  const handleFilter = () => {
    setFilters({ location, startTime, country, fromDate, toDate });
  };

  const setToday = () => {
    const today = new Date().toISOString().split('T')[0];
    setFromDate(today);
    setToDate(today);
  };

  const setThisWeekend = () => {
    const today = new Date();
    const day = today.getDay();
    const diffToSaturday = 6 - day;
    const diffToSunday = 7 - day;
    const saturday = new Date(today);
    const sunday = new Date(today);
    saturday.setDate(today.getDate() + diffToSaturday);
    sunday.setDate(today.getDate() + diffToSunday);
    setFromDate(saturday.toISOString().split('T')[0]);
    setToDate(sunday.toISOString().split('T')[0]);
  };

  const setThisMonth = () => {
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    setFromDate(firstDay.toISOString().split('T')[0]);
    setToDate(lastDay.toISOString().split('T')[0]);
  };

  const setThreeMonths = () => {
    const today = new Date();
    const threeMonthsLater = new Date(today);
    threeMonthsLater.setMonth(today.getMonth() + 3);
    setFromDate(today.toISOString().split('T')[0]);
    setToDate(threeMonthsLater.toISOString().split('T')[0]);
  };

  const setThisYear = () => {
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), 0, 1);
    const lastDay = new Date(today.getFullYear(), 11, 31);
    setFromDate(firstDay.toISOString().split('T')[0]);
    setToDate(lastDay.toISOString().split('T')[0]);
  };

  const setNextYear = () => {
    const today = new Date();
    const firstDay = new Date(today.getFullYear() + 1, 0, 1);
    const lastDay = new Date(today.getFullYear() + 1, 11, 31);
    setFromDate(firstDay.toISOString().split('T')[0]);
    setToDate(lastDay.toISOString().split('T')[0]);
  };

  const setAll = () => {
    setFromDate('');
    setToDate('');
  };

  return (
    <div className="flex flex-col items-center justify-start p-4 border border-gray rounded-lg bg-white text-black shadow-md space-y-4 w-3/4">
      {/* Date Filter Bar */}
      <div className="flex space-x-4 mb-4">
        <div className="flex items-center">
          <label className="flex items-center">
            <span className="pr-2 text-black">From:</span>
            <input
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              className="p-2 border border-gray rounded flex-grow"
            />
          </label>
        </div>
        <div className="flex items-center">
          <label className="flex items-center">
            <span className="pr-2 text-black">To:</span>
            <input
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              className="p-2 border border-gray rounded flex-grow"
            />
          </label>
        </div>

        <button onClick={setToday} className="p-2 text-black rounded border border-gray-dark">Today</button>
        <button onClick={setThisWeekend} className="p-2 text-black rounded border border-gray-dark">This Weekend</button>
        <button onClick={setThisMonth} className="p-2 text-black rounded border border-gray-dark">This Month</button>
        <button onClick={setThreeMonths} className="p-2 text-black rounded border border-gray-dark">3 Months</button>
        <button onClick={setThisYear} className="p-2 text-black rounded border border-gray-dark">This Year</button>
        <button onClick={setNextYear} className="p-2 text-black rounded border border-gray-dark">Next Year</button>
        <button onClick={setAll} className="p-2 text-black rounded border border-gray-dark">All</button>
      </div>
      {/* Other Filters */}
      <div className="flex space-x-2 mb-4">
        <select
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          className="p-2 border border-gray rounded flex-grow"
        >
          <option value="">Select Country</option>
          {uniqueCountries.map((country) => (
            <option key={country} value={country}>
              {country}
            </option>
          ))}
        </select>
        <select
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          className="p-2 border border-gray rounded flex-grow"
        >
          <option value="">Select Location</option>
          {uniqueLocations.map((loc) => (
            <option key={loc} value={loc}>
              {loc}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="Start Time (e.g., 11 AM or 11 PM)"
          value={startTime}
          onChange={(e) => setStartTime(e.target.value)}
          className="p-2 border border-gray-300 rounded flex-grow"
        />
        <button onClick={handleFilter} className="p-2 bg-error text-white rounded">
          Filter Events
        </button>
      </div>
    </div>
  );
};

export default EventFilter;