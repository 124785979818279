export const convertTime = (timeStr) => {
    const time = timeStr.toLowerCase().match(/(\d+):?(\d+)?\s*(am|pm)?/);
    if (!time) return null;
  
    let hours = parseInt(time[1], 10);
    const minutes = time[2] ? parseInt(time[2], 10) : 0;
    const period = time[3];
  
    if (period === 'pm' && hours < 12) hours += 12;
    if (period === 'am' && hours === 12) hours = 0;
  
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
  };