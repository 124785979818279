import React from "react";
import PropTypes from "prop-types";
import { FaMap, FaTicketAlt } from "react-icons/fa";
import GigImages from "../../constants/GigImages";
import { searchCountryByCode } from '../../utils/countryUtils';

const Event = ({ date, start_time, location, description, ticket_link, country, imageSrc }) => {

    const [countryData, setCountryData] = React.useState({ flag: "", name: "" });

    React.useEffect(() => {
      const fetchCountryData = async () => {
        if(country){
          const countryObject = await searchCountryByCode(country);
          if (countryObject) {
            setCountryData({ flag: countryObject.flag, name: countryObject.name });
          }      
        }
      }
      fetchCountryData();
    }, [country]);

    const getRandImage = () => {
        return GigImages[Math.floor(Math.random() * GigImages.length)];
      };

    const formattedDate = date 
      ? new Date(date).toLocaleDateString("en-UK", {
            weekday: "long",
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
        }).replace(",", " |")
      : "TBA";

    const formattedTime = start_time
        ? new Date(`1970-01-01T${start_time.replace("Z", "")}`).toLocaleTimeString("en-UK", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }).replace(/^0/, "")
        : "TBA";

    const title = "Celine Experience";
    
    const dateTimeDisplay =
      formattedDate === 'TBA' && formattedTime === 'TBA'
        ? 'To be announced'
        : `${formattedDate} at ${formattedTime}`;

    return (
        <div className="flex flex-row items-center gap-6 bg-white shadow-md rounded-lg overflow-hidden p-4 w-full max-w-4xl">
          {/* Image Section */}
          <div className="w-1/3">
            <img
              className="card-image"
              src={getRandImage()}
              alt={`Event: ${title || "No title provided"}`}
            />
          </div>
          
          {/* Details Section */}
          <div className="flex flex-col justify-between w-2/3">
            {/* Event Title */}
            <h3 className="text-2xl font-bold text-black">{title}</h3>
            {/* Date and Location */}

            <p className="text-3xl font-bold text-black">
              {dateTimeDisplay}
            </p>
            <div className="text-gray-dark ">
              <p className="mt-1 text-gray-dark">
                {countryData.flag && (
                  <span className="mr-2" title={countryData.name}>
                    {countryData.flag}
                  </span>
                )}
                {location}{' '}
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(location)}`}
                  className="text-link pl-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaMap className="inline-block" />
                </a>
              </p>            
            </div>
    
            {/* Button */}
            <div className="mt-4">
              <a href={ticket_link} target="_blank" rel="noopener noreferrer">
                <button className="px-6 py-2 border border-gray-dark rounded-full text-gray-dark hover:text-white hover:bg-card-background transition-all">
                  View Event →
                </button>
              </a>
            </div>
          </div>
        </div>
      );
};

Event.propTypes = {
  date: PropTypes.string.isRequired,
  start_time: PropTypes.string,
  location: PropTypes.string.isRequired,
  description: PropTypes.string,
  ticket_link: PropTypes.string.isRequired,
  imageSrc: PropTypes.string,
};

Event.defaultProps = {
  start_time: null,
  description: "No description provided.",
  imageSrc: "https://via.placeholder.com/150",
};

export default Event;
