import axios from 'axios';


export const fetchCountries = async () => {
  try {
    const response = await axios.get('https://restcountries.com/v3.1/all?fields=name,cca2,flag');
    return response.data.map(country => ({
      name: country.name.common,
      code: country.cca2,
      flag: country.flag,
    }));
  } catch (error) {
    console.error('Error fetching countries:', error);
    return [];
  }
};

export const searchCountryByCode = async (code) => {
    try {
        const response = await axios.get(`https://restcountries.com/v3.1/alpha/${code}?fields=name,cca2,flag`);
        const country = response.data;
        return {
            name: country.name.common,
            code: country.cca2,
            flag: country.flag,
        };
    }
    catch (error) {
        console.error('Error fetching countries:', error);
        return null;
    }
};

export const fetchCountryFlag = async (code) => {
    const country = await searchCountryByCode(code);
    return country ? country.flag : "🏴‍☠️"
};